<template>
  <a
    :download="downloadName"
    :href="downloadUrl"
    :disabled="this.data.length === 0"
    v-bind="$attrs"
  >
    <slot>
      <svg
        class="w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
    </slot>
  </a>
</template>

<script>
import { Parser } from "json2csv";

export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    downloadName: {
      default: "export.csv",
    },
  },
  computed: {
    downloadUrl() {
      if (this.data.length === 0) {
        return "javascript:void(0);";
      }

      const parser = new Parser({ fields: this.fields });

      return "data:text/csv," + encodeURIComponent(parser.parse(this.data));
    },
  },
};
</script>